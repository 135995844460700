.header {
    border-bottom: 1px solid #f4f4f4;
}
.header-action-button {
    background: #000;
    padding: 10px 15px;
    color: #fff;
    border-radius: 5px;
}
.header-action-title {
    margin-right: 15px;
    opacity: .5;
}
.header-progress {
    height: 5px;
    background-color: #fff;
}
.header-progress-bar {
    height: 5px;
    width: 50%;
    background-color: #1ca43a;
}

input::placeholder {
    color: #ccc !important;
    opacity: 1; /* Firefox */
}

input::-ms-input-placeholder { /* Edge 12 -18 */
    color: #ccc !important;
}

.signup-bottom-banner {
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background-color: #1ca43a;
    color: #fff;
    position: absolute;
    text-align: center;
}


@media (max-width:991px)  {
    .header-action-button {
        background: #eeeeee;
        padding: 6px 10px;
        color: #fff;
        border-radius: 7px;
        font-size: 12px;
        color: #6e6e6e;
        font-weight: bold;
    }
}

@media (max-width:767px)  {
    .header {
        margin-bottom: 20px;
        border-bottom: none;
    }
    .header-progress {
        background-color: #e2e2e2;
    }
}