@import './animate.css';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap');



:root {
  font-size: 16px;
  --primary-font-family: "Plus Jakarta Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --bs-font-sans-serif: var(--primary-font-family);
  --bs-border-color: #E5EAF2;
  --section-spacing: 64px;
  --heading-spacing: 32px;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-size: 14px;
  background-color: #f8f8f8;
}

td {
  vertical-align: middle;
}

#root {
  display: flex;
  flex-flow: column;
}

main {
  flex: 1;
  padding-top: var(--section-spacing);
  padding-bottom: var(--section-spacing);
}

body,
input,
textarea,
button {
  font-family: var(--primary-font-family)
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

img {
  object-fit: contain;
  max-width: 100%;
}

a,
button {
  cursor: pointer;
  text-decoration: unset;
}



/* == Utility Classes == */

.bg-green {
  background-color: #1ca43a;
}

.pb-110 {
  padding-bottom: 110px;
}

.flex-1 {
  flex: 1 !important
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor-pointer,
.cursor-pointer * {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.mb-section {
  margin-bottom: var(--section-spacing) !important;
}

.mt-section {
  margin-top: var(--section-spacing) !important;
}

.m-section {
  margin: var(--section-spacing) !important;
}

.pb-section {
  padding-bottom: var(--section-spacing) !important;
}

.pt-section {
  padding-top: var(--section-spacing) !important;
}

.p-section {
  padding: var(--section-spacing) !important;
}

.mb-heading {
  margin-bottom: var(--heading-spacing) !important;
}

.mt-heading {
  margin-top: var(--heading-spacing) !important;
}

.h-100-normal[class] {
  height: 100%;
}

.inset-0 {
  inset: 0;
}

.top-15 {
  top: 15px !important;
}

.left-15 {
  left: 15px !important;
}

.top-5 {
  top: 5px !important;
}

.right-5 {
  right: 5px !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.border-dashed {
  border-style: dashed !important;
}

.outline-0 {
  outline: unset !important
}


/* == Slider == */
.styled .swiper-button-next,
.styled .swiper-button-prev {
  --swiper-navigation-size: 14px;
  --swiper-navigation-color: var(--primary-main);
  background: white;
  height: 36px;
  width: 36px;
  border-radius: var(--bs-border-radius);
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow-3);
}



/* == Bootstrap Style Override == */
.container {
  --spacing: 30px;
  max-width: calc(1100px + calc(var(--spacing) * 2));
  padding-left: var(--spacing);
  padding-right: var(--spacing)
}

@media (max-width:991px) {
  .container {
    --spacing: 16px;
  }
}

p {
  margin-bottom: .5em;
}

.dropdown-menu {
  --bs-dropdown-padding-y: .3rem;
  --bs-dropdown-padding-x: .3rem;
  --bs-dropdown-font-size: 14px;
  --bs-dropdown-link-active-bg: var(--primary-main);
  --bs-dropdown-item-border-radius: 5px;
  --bs-dropdown-item-padding-y: 6px;
  --bs-dropdown-item-padding-x: 8px;
  --bs-dropdown-border-color: var(--bs-gray-200);
  --bs-dropdown-spacer: 7px;
  box-shadow: var(--shadow-4);
  background-clip: unset;
  animation: fadeInUp 0.25s forwards;
}


.form-control,
.form-select {
  font-weight: 500;
  caret-color: var(--primary-main);
  font-size: 14px;
}

select.form-control,
.form-select {
  height: 38px;
}

.form-check-input:checked {
  background-color: var(--primary-main);
  border-color: var(--primary-main);
}

.form-floating>label {
  max-width: 100%;
}

input.form-control-lg,
textarea.form-control-lg {
  font-weight: 500;
}


input.form-control:not(.is-invalid):focus,
textarea.form-control:not(.is-invalid):focus {
  box-shadow: unset;
  border-color: var(--primary-main) !important;
  outline: 1px solid var(--primary-main);
}

.text-primary {
  color: var(--primary-main) !important;
}

.text-blue {
  color: rgb(43 120 228) !important;
}

.form-label,
.form-floating>label {
  color: var(--bs-secondary);
}

.tooltip-inner {
  font-size: 12px;
  font-weight: 300;
}


/* date picker css */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__calendar-icon {
  height: 100%;
  width: auto;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 35px;
}


/* header customize mobile device */

.header-customize-mobile-device {
  position: absolute;
  top: 0;
  left: 0;


}

/* Media query for small devices (e.g., smartphones) */
@media screen and (max-width: 767px) {
  .header-customize-mobile-device {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 10px;
  }
}




/* Line show  */

.line-1,
.line-2,
.line-3,
.line-4,
.line-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

.line-2 {
  -webkit-line-clamp: 2
}

.line-3 {
  -webkit-line-clamp: 3
}

.line-4 {
  -webkit-line-clamp: 4
}

.line-5 {
  -webkit-line-clamp: 5
}

/* star rating feedback form */

.star-rating {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.star {
  font-size: 32px;
  cursor: pointer;
  transition: color 0.2s;
}

.star.selected {
  color: gold;
}

.star-rating-rating {
  font-size: 24px;
  margin-top: 10px;
}




/* Under line  */

.underline-hover:hover {
  text-decoration: underline;
}

.offcanvas.offcanvas-start {
  width: 80vw;
  max-width: 320px;
  background: #000;
  color: #fff;
}

header .offcanvas.offcanvas-start {
  width: none;
  max-width: none;
}



.btn:hover {
  background-color: #2b2b2b;
  border-color: #2b2b2b;
}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  background-color: #2b2b2b;
  border-color: #2b2b2b;
}


.dev-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 6px;
  z-index: 9999;
  background: #000000cf;
  color: #6dff00;
  text-align: center;
  margin: 5px;
  border-radius: 10px;
}

.cover-photo {
  object-fit: cover;
  -webkit-filter: brightness(0.8);; /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: brightness(0.8);
}