.lg-toolbar .lg-counter,
.lg-toolbar #lg-download-1,
.lg-sub-html,
.lg-toolbar .lg-zoom-in {
  display: none !important;
}


.line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
}